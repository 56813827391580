define("discourse/plugins/discourse-calendar/discourse/connectors/header-topic-title-suffix/event-date-container", ["exports", "discourse/plugins/discourse-calendar/discourse/components/event-date", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _eventDate, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EventDateContainer = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <EventDate @topic={{@outletArgs.topic}} />
  
  */
  {
    "id": "vrer3ucl",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,[[\"@topic\"],[[30,1,[\"topic\"]]]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[]]",
    "moduleName": "/srv/discourse/app/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/connectors/header-topic-title-suffix/event-date-container.js",
    "scope": () => [_eventDate.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = EventDateContainer;
});